import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout } from "./firebase";
import {
  query,
  collection,
  getDocs,
  where,
  doc,
  addDoc,
  deleteDoc,
  updateDoc,
  deleteField,
} from "firebase/firestore";

import TaskTable from "./components/TaskTable";
import Dashboard from "./components/Dashboard";

import { useState } from "react";
import {
  Navbar,
  Center,
  Tooltip,
  UnstyledButton,
  createStyles,
  Stack,
  Text,
  AppShell,
  Title,
  Table,
  Space,
  Button,
  Select,
  TextInput,
  Group,
  Container,
  List,
  FileInput,
  Image,
  Modal,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  IconHome2,
  IconGauge,
  IconSettings,
  IconLogout,
  IconChecklist,
} from "@tabler/icons";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

const useStyles = createStyles((theme) => ({
  link: {
    width: 50,
    height: 50,
    borderRadius: theme.radius.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },
  },

  active: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },
}));

function NavbarLink({ icon: Icon, label, active, onClick }) {
  const { classes, cx } = useStyles();
  return (
    <Tooltip label={label} position="right" transitionDuration={0}>
      <UnstyledButton
        onClick={onClick}
        className={cx(classes.link, { [classes.active]: active })}
      >
        <Icon stroke={1.5} />
      </UnstyledButton>
    </Tooltip>
  );
}

function AppNavbar({ pages, onPageChange }) {
  const [active, setActive] = useState();

  const pageChange = (page) => {
    onPageChange(pages[page]);
    setActive(page);
  };

  const links = pages.map((link, index) => (
    <NavbarLink
      {...link}
      key={link.label}
      active={index === active}
      onClick={() => pageChange(index)}
    />
  ));

  return (
    <Navbar width={{ base: 80 }} p="md">
      <Center>
        <IconChecklist size={36} color={"#495057"} />
      </Center>
      <Navbar.Section grow mt={50}>
        <Stack justify="center" spacing={0}>
          {links}
        </Stack>
      </Navbar.Section>
      <Navbar.Section>
        <Stack justify="center" spacing={0}>
          <NavbarLink icon={IconLogout} label="Logout" onClick={logout} />
        </Stack>
      </Navbar.Section>
    </Navbar>
  );
}

function Form({ fields, onSubmit, submitText = "Add Task", defaults }) {
  // const [fieldNames, setFieldNames] = useState([]);
  const [formValues, setFormValues] = useState({});

  // useEffect(() => {
  //   setFieldNames(fields.map((field) => field.name));
  // }, [fields]);

  useEffect(() => {
    if (defaults) {
      setFormValues(defaults);
    }
  }, [defaults]);

  const handleFormChange = (data, field) => {
    let temp = { ...formValues };
    temp[field] = data;
    setFormValues(temp);
  };

  const submit = () => {
    onSubmit(formValues);
  };

  return (
    <div className="App">
      <form onSubmit={submit}>
        {fields.map((field, index) => {
          return (
            <div key={index}>
              {field.type === "text" && (
                <TextInput
                  label={field.name}
                  name={field.name}
                  placeholder={field.name}
                  defaultValue={defaults ? defaults[field.name] : ""}
                  onChange={(event) =>
                    handleFormChange(event.target.value, field.name)
                  }
                />
              )}
              {field.type === "date" && (
                <DatePicker
                  onChange={(date) => {
                    handleFormChange(date.toLocaleDateString(), field.name);
                  }}
                  placeholder="Pick date"
                  defaultValue={defaults ? defaults[field.name] : ""}
                  label={field.name}
                  required
                />
              )}
            </div>
          );
        })}
      </form>
      <br />
      <Button onClick={submit}>{submitText}</Button>
    </div>
  );
}

function Edit({ show, closeCallback, data, fields, fetchTasks }) {
  let currentValues;
  if (data) {
    currentValues = data.data();
  }

  const update = async (d) => {
    await updateDoc(doc(db, "tasks", data.id), d);
    fetchTasks();
    closeCallback();
  };

  return (
    <Modal opened={show} onClose={closeCallback} title="Edit Task">
      <Form
        fields={fields}
        defaults={currentValues}
        onSubmit={update}
        submitText="Edit Task"
      />
    </Modal>
  );
}

function Overview({ user }) {
  const [tasks, setTasks] = useState([]);
  const [keys, setKeys] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [fields, setFields] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState();

  const fetchFields = async () => {
    const q = query(collection(db, "fields"), where("uid", "==", user?.uid));
    const d = await getDocs(q);
    setFields(d.docs.map((field) => field.data()));
    setKeys(
      d.docs.map((field) => {
        let data = field.data();
        return data.name;
      })
    );
    return d;
  };

  const fetchTasks = async () => {
    const q = query(collection(db, "tasks"), where("uid", "==", user?.uid));
    const d = await getDocs(q);
    setTasks(d.docs);
    return d;
  };

  const addTask = async (data) => {
    // console.log(data);
    data["uid"] = user.uid;
    const d = await addDoc(collection(db, "tasks"), data);
    fetchTasks();
    return d;
  };

  const deleteTask = async (id) => {
    const docRef = doc(db, "tasks", id);
    await deleteDoc(docRef);
    fetchTasks();
  };

  const deleteTasks = (tasks) => {
    tasks.forEach((t) => {
      deleteTask(t);
    });
    setSelectedTasks([]);
  };

  useEffect(() => {
    if (!user) return;
    fetchTasks();
    fetchFields();
  }, [user]);

  return (
    <div>
      <Title order={1}>Overview</Title>
      <Space h={60} />
      <Title order={4}>Current Tasks</Title>

      <TaskTable
        keys={keys}
        tasks={tasks}
        deleteCallback={deleteTask}
        editCallback={(data) => {
          setEditData(data);
          setEditOpen(true);
        }}
      />

      <Edit
        show={editOpen}
        closeCallback={() => {
          setEditOpen(false);
        }}
        data={editData}
        keys={keys}
        fields={fields}
        fetchTasks={fetchTasks}
      />

      <Space h={10} />
      {selectedTasks.length > 0 && (
        <Group>
          <Text>
            You have selected {selectedTasks.length} task
            {selectedTasks.length !== 1 && "s"}
          </Text>
          <Button
            variant={"outline"}
            size="xs"
            color={"red"}
            onClick={() => {
              deleteTasks(selectedTasks);
            }}
          >
            Delete
          </Button>
        </Group>
      )}

      <Space h={20} />

      <Title order={4}>Add a new Task</Title>

      <Form fields={fields} onSubmit={addTask}></Form>
    </div>
  );
}

const storage = getStorage();

function LogoSettings({ user }) {
  const fileRef = ref(storage, `logos/${user.uid}.jpg`);
  const [selectedFile, setSelectedFile] = useState();
  const [logoUrl, setLogoUrl] = useState();

  const getLogo = async () => {
    const url = await getDownloadURL(fileRef);
    setLogoUrl(url);
  };

  useEffect(() => {
    getLogo();
  }, [user]);

  const upload = async () => {
    if (selectedFile) {
      const uploadTask = uploadBytesResumable(fileRef, selectedFile, {
        contentType: "image/jpeg",
      });

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              console.log();
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
            default:
              console.log();
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setLogoUrl(downloadURL);
          });
        }
      );
    }
  };

  return (
    <>
      <Title order={4}>Logo Settings</Title>
      <div style={{ width: 100 }}>
        <Image radius="md" src={logoUrl} alt="Current Logo" />
      </div>

      {/* {selectedFile && <span>Selected file: {selectedFile.name}</span>} */}
      <FileInput
        type="file"
        value={selectedFile}
        onChange={setSelectedFile}
        placeholder="Upload your logo."
      />
      <Space h="md" />
      <Button onClick={upload}>Upload file</Button>
      <Space h={60} />
    </>
  );
}

function Settings({ user }) {
  const [fields, setFields] = useState([]);

  const form = useForm({
    initialValues: {
      name: "",
      type: "text",
    },
  });

  const fetchFields = async () => {
    const q = query(collection(db, "fields"), where("uid", "==", user?.uid));
    const d = await getDocs(q);
    setFields(d.docs);
    return d;
  };

  const addField = async (data) => {
    data["uid"] = user.uid;
    const d = await addDoc(collection(db, "fields"), data);
    fetchFields();
    return d;
  };

  const delField = async (f) => {
    const docRef = doc(db, "fields", f.id);

    const q = query(collection(db, "tasks"), where("uid", "==", user?.uid));
    const d = await getDocs(q);
    d.docs.forEach((doc) => {
      updateDoc(doc.ref, { [f.data().name]: deleteField() });
    });

    await deleteDoc(docRef);
    fetchFields();
    return d;
  };

  useEffect(() => {
    if (!user) return;
    fetchFields();
  }, [user]);

  return (
    <div>
      <Title order={1}>Settings</Title>
      <Space h={60} />
      <LogoSettings user={user} />
      <Title order={4}>Your Fields</Title>
      <Table striped>
        <thead>
          <tr>
            <th>Field Name</th>
            <th>Type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {fields.map((f, key) => {
            let data = f.data();
            return (
              <tr key={key}>
                <td>{data.name}</td>
                <td>{data.type}</td>
                <td>
                  <Button
                    variant="outline"
                    color="red"
                    size="xs"
                    onClick={() => {
                      delField(f);
                    }}
                  >
                    Delete Field
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Space h={30} />

      <Title order={4}>Add a new field</Title>
      <form onSubmit={form.onSubmit((values) => addField(values))}>
        <TextInput
          required
          label="Field Name"
          placeholder="Field Name"
          {...form.getInputProps("name")}
        />

        <Select
          required
          label="Field Type"
          placeholder="Pick one"
          data={[
            { value: "text", label: "Text" },
            { value: "date", label: "Date" },
          ]}
          {...form.getInputProps("type")}
        />

        <Group mt="md">
          <Button type="submit">Submit</Button>
        </Group>
      </form>
    </div>
  );
}

function DashboardWithLink({ userId }) {
  let link = `http://${window.location.host}/dashboard/${userId}`;
  return (
    <div>
      <Text>
        To view this page on other devices live go to:{" "}
        <Text variant="link" component="a" href={link}>
          {link}
        </Text>
      </Text>
      <Space h={30} />
      <Dashboard userId={userId}></Dashboard>
    </div>
  );
}

export default function Home() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");

    setCurrentPage(
      <Center style={{ height: "100%" }}>
        <Container>
          <Title align="center" order={1}>
            👋 Welcome {user.email}!
          </Title>
          <Space h={30} />
          <Text align="center">
            Workshop Tracker allows you to track what needs doing, and display
            tasks on always up-to-date displays.
          </Text>
          <Space h={30} />
          <Title order={4}>How to use:</Title>
          <List>
            <List.Item>
              You can configure which columns you need in settings.
            </List.Item>
            <List.Item>
              Once your columns are set up you can add and remove tasks on the
              home page.
            </List.Item>
            <List.Item>
              Use Dashboard to preview a live task view, and share the given
              link to see your tasks on any device live.
            </List.Item>
          </List>
        </Container>
      </Center>
    );
  }, [user, loading]);

  const pages = [
    {
      icon: IconHome2,
      label: "Home",
      element: <Overview user={user}></Overview>,
    },
    {
      icon: IconGauge,
      label: "Dashboard",
      element: <DashboardWithLink userId={user?.uid}></DashboardWithLink>,
    },
    // { icon: IconDeviceDesktopAnalytics, label: "Analytics" },
    // { icon: IconCalendarStats, label: "Releases" },
    // { icon: IconUser, label: "Account", element: <Account /> },
    // { icon: IconFingerprint, label: "Security" },
    {
      icon: IconSettings,
      label: "Settings",
      element: <Settings user={user}></Settings>,
    },
  ];

  return (
    <AppShell
      navbar={
        <AppNavbar
          pages={pages}
          onPageChange={(page) => {
            setCurrentPage(page.element);
          }}
        />
      }
    >
      <Container size={"100%"} style={{ height: "100%" }}>
        {currentPage}
      </Container>
    </AppShell>
  );
}
