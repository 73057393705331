import TaskTable from "./TaskTable";
import { useState, useEffect } from "react";
import {
  query,
  collection,
  where,
  onSnapshot,
  getDocs,
} from "firebase/firestore";
import { ref, getStorage, getDownloadURL } from "firebase/storage";
import { db } from "../firebase";
import { Image } from "@mantine/core";

const storage = getStorage();

export default function Dashboard({ userId }) {
  const [tasks, setTasks] = useState([]);
  const [keys, setKeys] = useState([]);
  const [logoUrl, setLogoUrl] = useState();
  const logoRef = ref(storage, `logos/${userId}.jpg`);

  const getLogo = async () => {
    const url = await getDownloadURL(logoRef);
    setLogoUrl(url);
  };

  useEffect(() => {
    const q = query(collection(db, "tasks"), where("uid", "==", userId));
    const unsub = onSnapshot(q, (snapshot) => {
      setTasks(snapshot.docs);
      fetchFields();
    });
    getLogo();
    return () => {
      unsub();
    };
  }, []);

  const fetchFields = async () => {
    const q = query(collection(db, "fields"), where("uid", "==", userId));
    const d = await getDocs(q);
    setKeys(
      d.docs.map((field) => {
        let data = field.data();
        return data.name;
      })
    );
    return d;
  };

  return (
    <div>
      <Image fit="contain" height={90} alt="Your Logo" withPlaceholder src={logoUrl} />

      <TaskTable keys={keys} tasks={tasks} />
    </div>
  );
}
