import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import Reset from "./Reset";
import Home from "./Home";
import DashboardPage from "./DashboardPage";
import { MantineProvider, Text } from "@mantine/core";
import NotFound from "./NotFound"

function App() {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <div className="app">
        <Router>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/reset" element={<Reset />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/dashboard/:id" element={<DashboardPage />} />
            <Route path="*" element={<NotFound />}>
              
          </Route>
          </Routes>
        </Router>
      </div>
    </MantineProvider>
  );
}

export default App;
